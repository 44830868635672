import * as Yup from "yup"

export const VARIATIONS = {
    Yes: "Yes",
    No: "No",
}

export const ADD_NOTE = {
    Yes: "Yes",
    No: "No",
}

export const FIELDS_NAME = {
    NAME: "name",
    DESCRIPTION: "description",
    PRICE: "price",
    VARIATION: "variations",
    VARIATION_TYPE: "variationType",
    // VARIATION_ITEMS: "variationItems",
    ADD_NOTE: "addNote",
    STATUS: "status",
}

export const initialValues = {
    [FIELDS_NAME.DESCRIPTION]: "",
    [FIELDS_NAME.NAME]: "",
    [FIELDS_NAME.STATUS]: "ACTIVE",
    [FIELDS_NAME.PRICE]: "",
    [FIELDS_NAME.VARIATION]: "",
    [FIELDS_NAME.VARIATION_TYPE]: "",
    // [FIELDS_NAME.VARIATION_ITEMS]: "",
    [FIELDS_NAME.ADD_NOTE]: "",
}

export const validationSchema = Yup.object({
    [FIELDS_NAME.NAME]:Yup.object()
    .required("Select item")
    .nullable("Select valid item"),

    [FIELDS_NAME.DESCRIPTION]: Yup.string().required("Enter item description"),

    [FIELDS_NAME.STATUS]: Yup.string().required("Select status"),
    [FIELDS_NAME.PRICE]: Yup.number("Enter numbers only")
        .required("Enter valid price")
        .test("", "Price must be between 1 and 9 digits", item => {
            let num = item?.toString()?.length
            return num <= 9 && num >= 1
        }),
    [FIELDS_NAME.VARIATION]: Yup.string().required("Select one"),
    [FIELDS_NAME.VARIATION_TYPE]: Yup.string().when(FIELDS_NAME.VARIATION, {
        is: selectedValue => {
            return selectedValue === "Yes"
        },
        then: () => Yup.string().required("Select one"),
    }),
    [FIELDS_NAME.ADD_NOTE]: Yup.string().required("Select status"),
})
