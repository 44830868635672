import React, { useState, useEffect, useRef } from "react"
import { Col, Row, Form, Input, FormGroup, Label } from "reactstrap"
import { useFormik } from "formik"
import toast from "react-hot-toast"
import { useParams } from "react-router-dom"

import { Modal } from "../../../components/Modal"
import { TextInput } from "../../../components/TextInput"
import { Button } from "../../../components/Button"
import FormikErrorText from "../../../components/FormikErrorText/FormikErrorText"
import { OverlayLoader } from "../../../components/Loader"
import ImageGallery from "../CommonUI/ImageGallery/ImageGallery"
import {
   GetPackageDetails,
   CreatePackage,
   UpdatePackage,
} from "../../../api/api.service"
import {
   initializeMediaUtil,
   uploadOnS3,
   finalizeMediaUtil,
} from "../../../utils/mediaUtils"
import { FIELDS_NAME, initialValues, validationSchema } from "./FormConfig"
import ActiveInactiveSwitch from "../CommonUI/ActiveInactiveSwitch/ActiveInactiveSwitch"
import { convertToSelectOptions } from "utils/commonUtils"
import { ConfiguratorPackagesStatus } from "../../../constants"

const TITLES = {
   MODAL_ADD_TITLE: "Add new package",
   MODAL_UPDATE_TITLE: "Update package",
   MODAL_DETAILS_TITLE: "Package details",
   ADD_BTN_TEXT: "Add package",
   UPDATE_BTN_TEXT: "Update package",
   DETAILS_BTN_TEXT: "Done",
}

const handleStatusConversion = status => {
   return status == ConfiguratorPackagesStatus.Active ? true : false
}

const handleUpdateExecution = async (id, data) => {
   let res = await UpdatePackage(id, data)
   toast.success("Package has been updated successfully !")
}

const ManagePackages = ({
   isDetailView = false,
   isOpen = false,
   onToggleModal = () => {},
   onSuccess = () => {},
   id = "",
}) => {
   const imagesRef = useRef(null)
   const { trailerId, categoryId } = useParams()
   const [isLoading, setIsLoading] = useState(false)
   const [status, setStatus] = useState(false)

   const [previousImages, setPreviousImages] = useState([])
   const [isFetching, setIsFetching] = useState(false)

   const formik = useFormik({
      enableReinitialze: true,
      initialValues: {
         ...initialValues,
      },
      validationSchema: validationSchema,
      onSubmit: values => {
         handleSubmit(values)
      },
   })

   useEffect(() => {
      handleFetchDetails()
      return () => {
         setIsLoading(false)
      }
   }, [id])

   const handleSubmit = values => {
      let bodyData = {
         configuratorTrailerId: Number(trailerId),
         packageName: values[FIELDS_NAME.NAME],
         price: values[FIELDS_NAME.PRICE].toString(),
         description: values[FIELDS_NAME.DESCRIPTION],
         status: values[FIELDS_NAME.STATUS],
      }

      if (id) {
         handleUpdate(bodyData)
      } else {
         handleAdd(bodyData)
      }
   }

   const uploadedMediaIDs = async images => {
      if (images) {
         try {
            let res = await handleUploadImages(images)
            return res?.length && res.map(item => item.id)
         } finally {
         }
      }
   }

   const handleUpdate = async data => {
      const payload = {
         ...data,
      }

      let userUploadedImages = imagesRef.current.getAllImages()

      if (!userUploadedImages.length && !previousImages.length) {
         return toast.error("Upload item images !")
      }

      try {
         setIsLoading(true)

         if (userUploadedImages.length) {
            uploadedMediaIDs(userUploadedImages).then(async res => {
               const apiFormatForImages = res.map(imageId => ({
                  mediaId: imageId,
               }))
               payload.images = [
                  ...(apiFormatForImages?.length && apiFormatForImages),
                  ...(previousImages?.length
                     ? previousImages.map(image => ({
                          mediaId: image.media.id,
                       }))
                     : []),
               ]

               await handleUpdateExecution(id, payload)
               handleModalClose()
               onSuccess()
            })
         } else {
            payload.images = [
               ...(previousImages?.length &&
                  previousImages.map(image => ({
                     mediaId: image.mediaId,
                  }))),
            ]

            await handleUpdateExecution(id, payload)
            handleModalClose()
            onSuccess()
         }
      } finally {
         setIsLoading(true)
      }
   }
   const handleAdd = async data => {
      let userUploadedImages = imagesRef.current.getAllImages()

      if (!userUploadedImages.length) {
         toast.error("Upload item images !")
         return
      }

      try {
         setIsLoading(true)
         let ids = await uploadedMediaIDs(userUploadedImages)
         let apiFormatForImages = ids.map(imageId => ({
            mediaId: imageId,
         }))
         data.images = [...(apiFormatForImages.length && apiFormatForImages)]

         let res = await CreatePackage(data)
         toast.success("Package has been added successfully !")
         onSuccess()
         handleModalClose()
      } catch (error) {
      } finally {
         setIsLoading(false)
      }
   }

   const handleFetchDetails = async () => {
      if (!id) return

      try {
         let res = await GetPackageDetails(id)

         formik.setValues({
            [FIELDS_NAME.NAME]: res?.packageName,
            [FIELDS_NAME.DESCRIPTION]: res?.description,
            [FIELDS_NAME.PRICE]: res?.price,

            [FIELDS_NAME.STATUS]: res.status,
         })
         setStatus(handleStatusConversion(res.status))
         setPreviousImages(res?.images)
      } finally {
         setIsFetching(false)
      }
   }

   const handleUploadImages = async images => {
      let apiCallIns
      let uploadedIds = []

      apiCallIns = images.map(item => {
         return handleMediaUpload(item)
      })

      try {
         setIsLoading(true)
         uploadedIds = (await Promise.allSettled(apiCallIns))
            .filter(item => item.status === "fulfilled")
            .map(id => id.value)
      } finally {
         setIsLoading(false)
      }

      return uploadedIds
   }

   const handleMediaUpload = async file => {
      return initializeMediaUtil(file).then(async res => {
         const credentials = res
         await uploadOnS3(file, credentials, handleImageUploadProgress)
         return await finalizeMediaUtil(credentials?.mediaId)
      })
   }

   const handleImageUploadProgress = progress => {}

   const handleModalClose = () => {
      formik.resetForm()
      setPreviousImages([])
      onToggleModal()
   }
   const handleSetStatus = evt => {
      let { name } = evt.target
      let alteredStatus = !status
      setStatus(alteredStatus)
      formik.setFieldValue(
         name,
         alteredStatus
            ? ConfiguratorPackagesStatus.Active
            : ConfiguratorPackagesStatus.Inactive
      )
   }

   return (
      <Modal
         isOpen={isOpen}
         handleModalToggling={handleModalClose}
         bodyClassName=""
         customButton={true}
         hideModalHeaderSeparator={true}
         headerClasses="header-container"
         sizeClasses="8"
         backdrop="static"
         modalTitle={
            isDetailView
               ? TITLES.MODAL_DETAILS_TITLE
               : id
               ? TITLES.MODAL_UPDATE_TITLE
               : TITLES.MODAL_ADD_TITLE
         }
         scrollable
      >
         <div style={{ pointerEvents: isLoading ? "none" : "auto" }}>
            <Row>
               <Col xs={12}>
                  <Form onSubmit={formik.handleSubmit}>
                     <Row className="mb-3 align-items-center">
                        <label className=" col-md-3 col-form-label clr-theme-primary">
                           Package Name
                        </label>
                        <div className="col-md-9">
                           <TextInput
                              inputClass={isDetailView && "input-readonly"}
                              placeholder="Enter package name"
                              name={FIELDS_NAME.NAME}
                              {...formik.getFieldProps(FIELDS_NAME.NAME)}
                           />
                           <FormikErrorText
                              formikInstance={formik}
                              fieldName={FIELDS_NAME.NAME}
                           />
                        </div>
                     </Row>

                     <Row className="mt-4 mb-3 align-items-center">
                        <label className=" col-md-3 col-form-label clr-theme-primary">
                           Price ($)
                        </label>
                        <div className="col-md-9">
                           <TextInput
                              type="number"
                              min={0}
                              step="0.000001"
                              inputClass={isDetailView && "input-readonly"}
                              placeholder="Enter package price"
                              name={FIELDS_NAME.PRICE}
                              {...formik.getFieldProps(FIELDS_NAME.PRICE)}
                           />

                           <FormikErrorText
                              formikInstance={formik}
                              fieldName={FIELDS_NAME.PRICE}
                           />
                        </div>
                     </Row>

                     <Row className="mb-3 align-items-center">
                        <label className=" col-md-3 col-form-label clr-theme-primary">
                           Package Description
                        </label>
                        <div className="col-md-9">
                           {!isDetailView && (
                              <TextInput
                                 size="md"
                                 type="textarea"
                                 // inputClass={
                                 //     isDetailView && "input-readonly"
                                 // }
                                 placeholder="Enter Package description"
                                 name={FIELDS_NAME.DESCRIPTION}
                                 {...formik.getFieldProps(
                                    FIELDS_NAME.DESCRIPTION
                                 )}
                              />
                           )}

                           {isDetailView && (
                              <p
                                 style={{
                                    wordWrap: "break-word",
                                    width: "100%",
                                    fontSize: "12px",
                                    marginLeft: "6px",
                                 }}
                              >
                                 {formik.values[FIELDS_NAME.DESCRIPTION].trim()}
                              </p>
                           )}

                           <FormikErrorText
                              formikInstance={formik}
                              fieldName={FIELDS_NAME.DESCRIPTION}
                           />
                        </div>
                     </Row>

                     <Row className="mt-4 mb-3 align-items-center">
                        <label className=" col-md-3 col-form-label clr-theme-primary">
                           Upload Images
                        </label>
                        <div className="col-md-9">
                           <ImageGallery
                              ref={imagesRef}
                              previousImages={previousImages}
                              removePreviousImages={setPreviousImages}
                              previewOnly={isDetailView}
                              isDetailView={isDetailView}
                           />
                        </div>
                     </Row>

                     <Row className="mb-3 align-items-center">
                        <label className=" col-md-3 col-form-label clr-theme-primary">
                           Status
                        </label>
                        <div className="col-md-9">
                           <ActiveInactiveSwitch
                              isChecked={status}
                              isDisable={isDetailView}
                              name={FIELDS_NAME.STATUS}
                              onChange={handleSetStatus}
                           />
                           <FormikErrorText
                              formikInstance={formik}
                              fieldName={FIELDS_NAME.STATUS}
                           />
                        </div>
                     </Row>

                     <div className="d-flex justify-content-center mt-3 mt-2">
                        <Button
                           isLoading={isLoading}
                           type={isDetailView ? "button" : "submit"}
                           title={
                              isDetailView
                                 ? TITLES.DETAILS_BTN_TEXT
                                 : id
                                 ? TITLES.UPDATE_BTN_TEXT
                                 : TITLES.ADD_BTN_TEXT
                           }
                           className="header-button"
                           onClick={isDetailView && handleModalClose}
                        />
                     </div>
                  </Form>
               </Col>
            </Row>

            {isFetching && <OverlayLoader />}
         </div>
      </Modal>
   )
}

export default React.memo(ManagePackages)
