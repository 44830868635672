import React, { useState } from "react"
import { Col, Row } from "reactstrap"
import { useParams } from "react-router-dom"

import { DataTable } from "../../../components/DataTable"
import { useLocalPaginatedRequest } from "../../../hooks/useLocalPaginatedRequest"
import { Pagination } from "../../../components/Pagination"
import { GetDepartmentInventoryListing } from "../../../api/api.service"
import { SearchBox } from "../../../components/SearchBox"

const COLUMNS = {
    ITEM_NAME: "Item name",
    QUANTITY: "Quantity",
}
const DATA_KEY = "data"
const TOTAL_KEY = "total"

const BuildStationItems = ({}) => {
    const { trailerId, categoryId, stationId } = useParams()
    const [statusFilters, setStatusFilters] = useState(null)
    const [selection, setSelection] = useState({})

    const {
        data,
        isFetching,
        page,
        searchText,
        pageSize,
        total,
        handleSearchChange,
        request,
        handlePageClick,
        onChangePageSize,
        setTableDataChangeLoading,
        tableDataChangeLoading,
        setData,
    } = useLocalPaginatedRequest({
        requestFn: GetDepartmentInventoryListing,
        params: {
            departmentId: stationId,
        },
        deps: [statusFilters],
        dataKey: DATA_KEY,
        totalKey: TOTAL_KEY,
    })

    return (
        <>
            <div className="mb-3 trailer-container">
                <div className="d-flex justify-content-end admin-inventory-management-general-inventory-responsiveness trailer-btn-container">
                    <div className="ad-location-search-box">
                        <SearchBox
                            inputClasses="vendor-search-input input-search"
                            labelClass="d-none"
                            placeholder="Search"
                            searchIcon={true}
                            searchIconClass="vendor-table-search-icon"
                            conClass="vendor-table-search-inner-class"
                            searchText={searchText}
                            onChange={handleSearchChange}
                        />
                    </div>

                    <div />
                </div>
            </div>

            <Row>
                <Col md={12}>
                    <DataTable
                        data={data}
                        tableClasses="base-feature-category-table"
                        loading={isFetching}
                        config={[
                            {
                                title: COLUMNS.ITEM_NAME,
                                className: "",
                                render: data => data.itemName,
                            },
                            {
                                title: COLUMNS.QUANTITY,
                                className: "",
                                render: data => data.inStock,
                            },
                        ]}
                    />
                </Col>
                <Pagination
                    currentPage={page}
                    totalData={total}
                    onPageClick={handlePageClick}
                />
            </Row>
        </>
    )
}

export default BuildStationItems
