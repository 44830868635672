import React, { useState } from "react"
import { Col, Row } from "reactstrap"
import { useHistory, useParams } from "react-router-dom"
import toast from "react-hot-toast"

import settingsIcon from "../../../assets/images/icons/settings-icon.svg"
import eyeIcon from "../../../assets/images/icons/eye-icon.svg"
import editIcon from "../../../assets/images/icons/edit-icon.svg"
import customStyles from "../../Common/AutoCompleteStyles"
import ActiveInactiveSwitch from "../CommonUI/ActiveInactiveSwitch/ActiveInactiveSwitch"
import { DataTable } from "../../../components/DataTable"
import { useLocalPaginatedRequest } from "../../../hooks/useLocalPaginatedRequest"
import { Pagination } from "../../../components/Pagination"
import {
    CreateBuildStation,
    GetBuildStationDetails,
    GetBuildStationsListing,
    UpdateBuildStation,
} from "../../../api/api.service"
import { APP_ROUTES, getRoute } from "../../../helpers/routeHelpers"
import { SearchBox } from "../../../components/SearchBox"
import { Button } from "../../../components/Button"
import { AutoComplete } from "../../../components/AutoComplete"
import { ConfiguratorBaseFeaturesCategoryStatus } from "../../../constants"
import { convertToSelectOptions } from "../../../utils/commonUtils"
import { convertDateTime, dateTimeFormat } from "utils/dateFormatUtils"
import ManageSettingsCategory from "../CommonUI/ManageSettingsCategory/ManageSettingsCategory"
import ManageStations from "./ManageStations"

const COLUMNS = {
    ID: "Id.",
    STATION_NAME: "Station",
    NO_OF_ITEMS: "No. of items",
    CREATED_AT: "Created / Updated",
    ACTIONS: "Actions",
}

const DATA_KEY = "data"
const TOTAL_KEY = "total"

const TITLES = {
    MODAL_ADD_TITLE: "Add Station",
    MODAL_UPDATE_TITLE: "Update Station",
    MODAL_DETAILS_TITLE: "Station details",
    ADD_BTN_TEXT: "Add Station",
    UPDATE_BTN_TEXT: "Update Station",
    DETAILS_BTN_TEXT: "Done",
}

const Build = ({}) => {
    const history = useHistory()
    const { trailerId } = useParams()
    const [statusFilters, setStatusFilters] = useState(null)
    const [selection, setSelection] = useState({})

    const {
        data,
        isFetching,
        page,
        searchText,
        pageSize,
        total,
        handleSearchChange,
        request,
        handlePageClick,
        onChangePageSize,
        setTableDataChangeLoading,
        tableDataChangeLoading,
        setData,
    } = useLocalPaginatedRequest({
        requestFn: GetBuildStationsListing,
        params: {
            trailerId: trailerId,
            ...(statusFilters?.value && { status: statusFilters?.value }),
        },
        deps: [statusFilters],
        dataKey: DATA_KEY,
        totalKey: TOTAL_KEY,
    })

    const [eventsType, setEventsType] = useState({
        isManage: null,
        isUpdate: null,
        isDetails: null,
    })

    const handleInitManage = obj => {
        setEventsType(prev => ({ ...prev, isManage: true }))
        setSelection(obj)
    }

    const handleNavigateToStationItems = obj => {
        setSelection(obj)

        return history.push(
            getRoute(
                APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_BUILD_STATION_ITEMS,
                {
                    trailerId: trailerId,
                    stationId: obj.departmentId,
                }
            )
        )
    }
    const handleSelectStatusOption = option => {
        setStatusFilters(option)
    }

    return (
        <>
            <div className="mb-3 trailer-container">
                <div className="d-flex align-items-center justify-content-end admin-inventory-management-general-inventory-responsiveness trailer-btn-container">
                    <SearchBox
                        inputClasses="vendor-search-input input-search"
                        labelClass="d-none"
                        placeholder="Search"
                        searchIcon={true}
                        searchIconClass="vendor-table-search-icon"
                        conClass="vendor-table-search-inner-class"
                        searchText={searchText}
                        onChange={handleSearchChange}
                    />
                    <div />

                    {/* 
                    Remove due to new design
                    <Button
                        className={`header-button headerButtonResponsive`}
                        title="Add Station"
                        onClick={() => handleInitManage({})}
                    /> */}
                </div>
            </div>

            <Row>
                <Col md={12}>
                    <DataTable
                        data={data}
                        tableClasses="base-feature-table"
                        loading={isFetching}
                        config={[
                            {
                                title: COLUMNS.ID,
                                className: "",
                                render: data => data.id || "-",
                            },

                            {
                                title: COLUMNS.STATION_NAME,
                                className: "",
                                render: data =>
                                    data.department?.departmentName || "-",
                            },
                            {
                                title: COLUMNS.CREATED_AT,
                                className: "",
                                render: data => {
                                    return convertDateTime({
                                        date: data.updatedAt,
                                        customFormat:
                                            dateTimeFormat.casitaCustomDateFormat,
                                        dateOnly: true,
                                    })
                                },
                            },

                            {
                                title: COLUMNS.NO_OF_ITEMS,
                                className: "",
                                render: data => data?.totalItems,
                            },

                            {
                                title: COLUMNS.ACTIONS,

                                render: data => (
                                    <div className="d-flex gap-2 pointer">
                                        {/* 
                                        Remove due to new design
                                        <img
                                            src={editIcon}
                                            onClick={() =>
                                                handleInitManage(data)
                                            }
                                        /> */}

                                        <img
                                            src={eyeIcon}
                                            onClick={() =>
                                                handleNavigateToStationItems(
                                                    data
                                                )
                                            }
                                        />
                                    </div>
                                ),
                            },
                        ]}
                    />
                </Col>
                <Pagination
                    currentPage={page}
                    totalData={total}
                    onPageClick={handlePageClick}
                />
            </Row>

            {eventsType.isManage && (
                <ManageStations
                    request={request}
                    TITLES={TITLES}
                    isOpen={eventsType.isManage}
                    trailerId={trailerId}
                    id={selection?.id}
                    data={selection}
                    onToggleModal={() => {
                        setSelection({})
                        setEventsType(prev => ({
                            ...prev,
                            isManage: false,
                        }))
                    }}
                />
            )}
        </>
    )
}

export default Build
